import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from '../context/AuthContext';
import ErrorFallback from './errors/ErrorFallback';
import marketplaceService from '../services/marketplaceService';
import alertNotification from '../utils/alertNotification';
import SupplierOrdersTable from '../components/marketplaceOrders/supplierOrders/SupplierOrdersTable';
import StatusSection from '../components/marketplaceOrders/StatusSection';
import { classNames } from '../utils/conditionalClasses';
import Pagination from '../components/common/Pagination';
import OrderFilterForm from '../components/marketplaceOrders/OrderFilterForm';
import StatusChangeModal from '../components/marketplaceOrders/StatusChangeModal';
import { useSearchParams } from 'react-router-dom';
import { EtsyOrder, parseBoolean } from './MarketplaceOrders';
import ExportXLS from '../components/marketplace/ExportXLS';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import LoadingComponent from '../components/common/LoadingComponent';
import httpClient from '../utils/httpClient';
import { DocumentDownload } from 'iconsax-react';

const MarketplaceSupplierOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const printRef = useRef<HTMLTableElement>(null);
  const { session } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  let temp = Object.fromEntries([...searchParams]);
  const [queryString, setQueryString] = useState('');
  const [queryParams, setQueryParams] = useState<any>({
    shop: temp?.shop || '',
    country: temp?.country || '',
    startDate: temp?.startDate || ('' as any),
    endDate: temp?.endDate || ('' as any),
    isGift: parseBoolean(temp?.isGift) || '',
    isGiftWrap: parseBoolean(temp?.isGiftWrap) || '',
    customer: temp?.customer || '',
    label: temp?.label || '',
    orderId: temp?.orderId || '',
  });
  const [shops, setShops] = useState<any[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [balance, setBalance] = useState<number>();
  const [labelCounts, setLabelCounts] = useState();

  const getSupplierOrders = async () => {
    setIsLoading(true);
    try {
      const res = await marketplaceService.getSupplierOrders(page, queryParams);
      const groupBySellerOrderId = (orders: any) => {
        // İlk olarak sellerOrderId'ye göre gruplama yapmak için bir nesne oluşturuyoruz
        const groupedBySellerOrderId = orders.reduce((acc: any, order: any) => {
          order.seller_order_items.forEach((item: any) => {
            const { sellerOrderId } = item;

            // Eğer sellerOrderId daha önce eklenmemişse, yeni bir array başlat
            if (!acc[sellerOrderId]) {
              acc[sellerOrderId] = { ...order, seller_order_items: [item] };
            } else {
              // Daha önce eklenmişse, mevcut seller_order_items dizisine ekle
              acc[sellerOrderId].seller_order_items.push(item);
            }
          });

          return acc;
        }, {});

        // groupedBySellerOrderId nesnesindeki değerleri bir array'e dönüştür
        const groupedArray = Object.values(groupedBySellerOrderId);

        return groupedArray;
      };

      // const groupedById = res.data.data.reduce((acc: any, current: any) => {
      //   const { id, seller_order_items } = current;

      //   if (!acc[id]) {
      //     acc[id] = { ...current, seller_order_items: [...seller_order_items] };
      //   } else {
      //     acc[id].seller_order_items.push(...seller_order_items);
      //   }

      //   return acc;
      // }, {});
      setOrders(
        groupBySellerOrderId(res.data.data).sort(
          (a: any, b: any) =>
            (new Date(b.orderDate) as any) - (new Date(a.orderDate) as any)
        ) as EtsyOrder[]
      );
      setLabelCounts(res.data.labelCount);
      setTotalPage(res.data.totalPageCount);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePrint = async () => {
    setIsPdfLoading(true);
    if (!printRef.current) {
      return;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const canvas = await html2canvas(printRef.current, {
      useCORS: true,
      allowTaint: true,
      scale: 2,
      logging: true,
    });
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    for (let i = 0; i < 2; i++) {
      if (i !== 0) pdf.addPage();
      pdf.addImage(
        data,
        'PNG',
        0,
        -(i * pdf.internal.pageSize.getHeight()),
        pdfWidth,
        pdfHeight
      );
    }
    pdf.save('print.pdf');
    setIsPdfLoading(false);
  };

  const getWalletBalance = async () => {
    try {
      const res = await marketplaceService.getWalletBalance();

      setBalance(Number(res.data.balance));
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const getShops = async () => {
    try {
      const res = await httpClient.get('/api/v1/marketplace/supplier/stores');
      setShops(res.data.data);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  useEffect(() => {
    getShops();
    getSupplierOrders();
    getWalletBalance();
  }, [page, queryString, queryParams]);

  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <OrderFilterForm
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          shops={shops}
          page={page}
          setInputPage={setInputPage}
          setQueryString={setQueryString}
          setPage={setPage}
        />
        <div className="bg-white rounded-xl py-4 mt-4">
          <div className="w-full px-4 my-4 flex justify-between">
            {session?.isSupplier && (
              <button
                disabled={selectedOrders.length === 0}
                className={classNames(
                  selectedOrders.length === 0
                    ? 'text-[#4B5563] bg-[#D1D5DB]'
                    : 'text-white bg-[#2563EB]',
                  ' text-xs 2xl:text-sm px-4 py-2 rounded-lg '
                )}
                // onClick={() => setIsStatusChangeModalOpen(true)}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Durum Değiştir{' '}
                {selectedOrders.length > 0 && (
                  <span className="text-sm">({selectedOrders.length})</span>
                )}
              </button>
            )}
            <div className="flex space-x-2 items-center">
              <button
                className="border-[#E3211E] border-2 text-[#E3211E] flex space-x-1 rounded-full w-36 py-2 px-4"
                onClick={handlePrint}
                disabled={isPdfLoading}
              >
                <DocumentDownload size="24" color="#E3211E" />{' '}
                {isPdfLoading ? <LoadingComponent /> : <p>PDF İndir</p>}
              </button>
              {/* <ExportXLS queryParams={queryParams} /> */}
              <div className="bg-[#047857] py-2 px-4 rounded-full border border-[#047857]">
                <p className="text-white font-medium">
                  Bakiye: ${Number(balance).toFixed(1)}
                </p>
              </div>
            </div>
          </div>

          <StatusSection
            labelCounts={labelCounts}
            setQueryParams={setQueryParams}
            setPage={setPage}
            setInputPage={setInputPage}
          />
        </div>
        {/* <div className="flex flex-col flex-shrink-0 bg-white rounded-2xl p-5 shadow-lg mt-2 sm:p-4 relative"> */}
        <SupplierOrdersTable
          ref={printRef}
          setIsModalOpen={setIsModalOpen}
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          isLoading={isLoading}
          orders={orders}
          page={page}
        />
        {/* </div> */}
      </ErrorBoundary>
      <Pagination
        page={page}
        setPage={setPage}
        totalPageCount={totalPage}
        inputPage={inputPage}
        setInputPage={setInputPage}
      />
      {selectedOrders.length > 0 && isModalOpen && (
        <StatusChangeModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          getSupplierOrders={getSupplierOrders}
          // refetchData={refetchData}
        />
      )}
    </div>
  );
};

export default MarketplaceSupplierOrders;
