import {
  Box1,
  CloseSquare,
  EyeSlash,
  InfoCircle,
  Link2,
  TickCircle,
  Ticket,
  TruckFast,
  Wallet1,
} from 'iconsax-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import API_URL from '../../../config';
import { classNames } from '../../../utils/conditionalClasses';
import { formatDateTR } from '../../../utils/date';
import LoadingComponent from '../../common/LoadingComponent';
import { Tooltip } from 'react-tooltip';
import ReactCountryFlag from 'react-country-flag';
import MatchedProductModal from './MatchedProductModal';

export type LabelKey =
  | 'waitingMatch'
  | 'waitingPayment'
  | 'producing'
  | 'ready'
  | 'waitingLabel'
  | 'shipped'
  | 'delivered';

export const labels = {
  waitingMatch: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <Link2 size="18" color="#4F46E5" variant="Bold" />
      <p className="text-[#4F46E5] text-sm">Eşleşme Bekliyor</p>
    </div>
  ),

  waitingPayment: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0D9488] px-2 p-1 w-full">
      <Wallet1 size="18" color="#0D9488" />
      <p className="text-[#0D9488] text-sm">Ödeme Bekliyor</p>
    </div>
  ),
  waitingProducing: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#EC4899] px-2 p-1 w-[200px]">
      <Box1 size="18" color="#EC4899" />
      <p className="text-[#EC4899] text-sm">Üretim Bekliyor</p>
    </div>
  ),
  producing: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#7C3AED] px-2 p-1 w-full">
      <Box1 size="18" color="#7C3AED" />
      <p className="text-[#7C3AED] text-sm">Üretiliyor</p>
    </div>
  ),
  // ready: (
  //   <div className="flex space-x-2 justify-center items-center rounded-full border border-[#06B6D4] px-2 p-1 w-full">
  //     <Box1 size="18" color="#06B6D4" />
  //     <p className="text-[#06B6D4] text-sm">Hazır</p>
  //   </div>
  // ),
  // waitingLabel: (
  //   <div className="flex space-x-2 justify-center items-center rounded-full border border-[#F59E0B] px-2 p-1 w-full">
  //     <TicketStar size="18" color="#F59E0B" />
  //     <p className="text-[#F59E0B] text-sm">Etiket Bekliyor</p>
  //   </div>
  // ),
  shipped: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0EA5E9] px-2 p-1 w-full">
      <TruckFast size="18" color="#0EA5E9" />
      <p className="text-[#0EA5E9] text-sm">Kargoya Verildi</p>
    </div>
  ),
  delivered: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#059669] px-2 p-1 w-full">
      <TickCircle size="18" color="#059669" />
      <p className="text-[#059669] text-sm">Teslim Edildi</p>
    </div>
  ),
  hidden: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <EyeSlash size="18" color="#4F46E5" variant="Bold" />
      <p className="text-[#4F46E5] text-sm">Gizli</p>
    </div>
  ),
  cancelled: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <CloseSquare size="18" color="red" variant="Bold" />
      <p className="text-red-500 text-sm">Gizli</p>
    </div>
  ),
};
const tableHeader = [
  { name: 'Seç' },

  { name: 'Sipariş' },
  { name: 'Fotoğraf' },
  { name: 'Ürün Açıklaması' },
  { name: 'Fiyat' },
  { name: 'Kargo Etiketi' },

  { name: 'Tasarım' },

  { name: 'Satıcı' },
];
interface Props {
  isLoading: boolean;
  orders: any[];
  page: number;
  setSelectedOrders: React.Dispatch<React.SetStateAction<any>>;
  selectedOrders: any;
  ref: any;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SupplierOrdersTable = React.forwardRef<HTMLTableElement, Props>(
  (
    {
      setSelectedOrders,
      page,
      selectedOrders,
      orders,
      isLoading,
      setIsModalOpen,
    },
    ref
  ) => {
    const [selectedOrderItem, setSelectedOrderItem] = useState(null);

    const toggleOrderSelection = (selectedItem: any) => {
      setSelectedOrders((prevSelectedOrders: any) => {
        const isAlreadySelected = prevSelectedOrders.some(
          (orderItem: any) =>
            orderItem.seller_order_items[0].id ===
            selectedItem.seller_order_items[0].id
        );
        if (isAlreadySelected) {
          return prevSelectedOrders.filter(
            (orderItem: any) =>
              orderItem.seller_order_items[0].id !==
              selectedItem.seller_order_items[0].id
          );
        } else {
          return [...prevSelectedOrders, selectedItem];
        }
      });
    };

    const downloadLabel = async (url: string) => {
      url = url.includes('https') ? url : `${API_URL}${url}`;
      console.log(url);
      const response = await fetch(url);
      console.log(response);
      const blob = await response.blob();
      const filename = 'label.pdf';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    };

    return (
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8">
              <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
                <div className="inline-block min-w-full align-middle h-[100vh]">
                  <table className="min-w-[1280px] table-fixed" ref={ref}>
                    <thead>
                      <tr>
                        {tableHeader.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="sticky top-0 z-10 bg-headerPrimary py-3.5 pl-4 pr-3 text-sm text-center font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                              // scope="col"
                            >
                              {' '}
                              <p className="text-xs md:text-sm">{item.name}</p>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    {isLoading ? (
                      <div className="w-full flex justify-center">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                        <tr className="h-10"></tr>
                        {orders?.length > 0 ? (
                          orders?.map((order: any, idx) => {
                            return (
                              <tr
                                key={idx}
                                className={classNames(
                                  idx === 0
                                    ? 'border-none'
                                    : 'border-b-[#D1D5DB]',
                                  ' rounded-xl'
                                )}
                              >
                                <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                                  <div className="flex justify-center">
                                    {
                                      <input
                                        type="checkbox"
                                        className="rounded-sm border-blue-500"
                                        checked={selectedOrders?.some(
                                          (orderItem: any) =>
                                            orderItem.seller_order_items[0]
                                              .sellerOrderId ===
                                            order.seller_order_items[0]
                                              .sellerOrderId
                                        )}
                                        onChange={() =>
                                          toggleOrderSelection(order)
                                        }
                                      />
                                    }
                                  </div>
                                </td>

                                <td className="py-4 text-xs sm:pl-0 whitespace-nowrap text-gray-500">
                                  <div className="flex flex-col items-start space-y-1">
                                    <p>
                                      <span className="text-headerPrimary">
                                        Tarih:
                                      </span>{' '}
                                      {formatDateTR(order.orderDate)}
                                    </p>

                                    <p>
                                      <span className="text-headerPrimary">
                                        Sipariş no:{' '}
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.platformOrderId
                                      }
                                    </p>
                                    <p>
                                      <span className="text-headerPrimary">
                                        Sipariş adedi:
                                      </span>{' '}
                                      {order.seller_order_items?.length}
                                    </p>
                                    <p>
                                      <span className="text-headerPrimary">
                                        Müşteri:{' '}
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.fullName
                                      }
                                    </p>
                                    <div className="flex items-center space-x-1">
                                      <p className="text-headerPrimary">
                                        Ülke:{' '}
                                      </p>
                                      <p>
                                        {
                                          order?.seller_order_items[0]
                                            ?.seller_order?.seller_etsy_orders
                                            .customerCountry
                                        }
                                      </p>
                                      <Tooltip
                                        anchorSelect={`#address${idx}`}
                                        content={
                                          order?.seller_order_items[0]
                                            ?.seller_order?.address
                                        }
                                        style={{
                                          width: '200px',
                                        }}
                                      />
                                      <ReactCountryFlag
                                        id={`address${idx}`}
                                        className="ml-1 text-base"
                                        svg
                                        countryCode={
                                          order?.seller_order_items[0]
                                            ?.seller_order?.seller_etsy_orders
                                            .customerCountry
                                        }
                                      />
                                    </div>
                                    <button
                                      className={
                                        'text-white bg-[#1D4ED8] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex items-center justify-center space-x-2 cursor-pointer'
                                      }
                                      // disabled={selectedOrders.length === 0}
                                      onClick={() => {
                                        toggleOrderSelection(order);
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      <p>Durum Değiştir</p>
                                    </button>
                                    <Link
                                      id="message"
                                      target="_blank"
                                      to={`/messages?to=${order?.seller_order_items[0]?.seller_order.userId}&name=${order?.seller_order_items[0]?.seller_order.user.firstName} ${order?.seller_order_items[0]?.seller_order.user.lastName}`}
                                      className="w-full mb-2 block text-center text-xs text-[#1D4ED8] border-2 px-1 py-1 border-[#1D4ED8] rounded-full"
                                    >
                                      Mesaj Gönder
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={classNames(
                                      order?.seller_order_items.length > 1
                                        ? 'space-y-2'
                                        : 'space-y-0',
                                      ' flex flex-col items-center justify-center w-[272px]'
                                    )}
                                  >
                                    {order?.seller_order_items.map(
                                      (item: any) => {
                                        return (
                                          <div className="relative">
                                            <img
                                              onLoad={() => {}}
                                              className="h-[200px] w-[200px] aspect-square object-cover object-center rounded-lg"
                                              src={
                                                item.seller_product.images[0]
                                                  ? item.seller_product
                                                      .images[0]
                                                  : '/images/image-not-found.png'
                                              }
                                              alt="Product"
                                            />
                                            <Tooltip
                                              anchorSelect={`#${item?.id}-matched`}
                                              content="Eşleşen ürün bilgisi"
                                            />

                                            <button
                                              id={`${item?.id}-matched`}
                                              className="bg-[#4F46E5] rounded-lg w-10 h-10 absolute top-2 right-2"
                                              onClick={() => {
                                                setSelectedOrderItem(item.id);
                                              }}
                                            >
                                              <Link2
                                                color="white"
                                                variant="Bold"
                                                className="mx-auto"
                                              />
                                            </button>
                                            <MatchedProductModal
                                              setSelectedOrderItem={
                                                setSelectedOrderItem
                                              }
                                              title={
                                                item.seller_product
                                                  .supplierProductVariantName
                                              }
                                              imgUrl={
                                                item.seller_product
                                                  .supplierProductVariantImages[0]
                                              }
                                              sku={
                                                item.seller_product
                                                  .supplierProductVariantSku
                                              }
                                              index={item.id}
                                              selectedIndex={selectedOrderItem}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </td>
                                <td className="py-4 text-xs md:text-sm text-left overflow-hidden text-gray-500 px-3 min-w-[230px]">
                                  <div className="flex flex-col items-start space-y-4 ">
                                    {order?.seller_order_items.map(
                                      (item: any, index: any) => (
                                        <div
                                          key={index}
                                          className="flex items-center space-x-4 px-2 pb-2 h-[200px]"
                                        >
                                          {/* <input
                                            type="checkbox"
                                            className="rounded-sm border-blue-500"
                                            checked={selectedOrders?.some(
                                              (orderItem: any) =>
                                                orderItem.id === item.id
                                            )}
                                            onChange={() =>
                                              toggleOrderSelection(item)
                                            }
                                          /> */}

                                          <div className="flex flex-col space-y-2">
                                            <p className="text-xs text-left text-[#1F2937] overflow-hidden h-16 font-medium">
                                              {item.seller_product.title}
                                            </p>

                                            <p className="text-xs text-[#1F2937]">
                                              Adet:{' '}
                                              <span className="text-gray-500">
                                                {item.quantity}
                                              </span>
                                            </p>

                                            <div className="text-xs text-left text-gray-500">
                                              {(!item.customizationData ||
                                                Object.keys(
                                                  item.customizationData
                                                ).length < 1) &&
                                                Object.entries(
                                                  item.seller_product
                                                    .attributes[0]
                                                ).map(([key, value]) => (
                                                  <div
                                                    className="flex space-x-1"
                                                    key={`${index}-${key}`}
                                                  >
                                                    <p className="text-[#1F2937] font-medium">
                                                      {key}:
                                                    </p>
                                                    <p>{value as string}</p>
                                                  </div>
                                                ))}
                                              <p className="font-medium text-[#1F2937]">
                                                Kişiselleştirme:{' '}
                                                <span className="font-normal">
                                                  {item.personalization
                                                    ? item.personalization
                                                    : Object.keys(
                                                        item.customizationData
                                                      ).length > 0
                                                    ? item.customizationData
                                                        .splice(9)
                                                        .join(' ')
                                                        .replaceAll(
                                                          '- Ek Maliyet: 0\n',
                                                          ''
                                                        )
                                                    : '-'}
                                                </span>
                                              </p>
                                              <div className="flex space-x-1">
                                                <span className="text-[#1F2937]">
                                                  Hediye mi?:
                                                </span>{' '}
                                                <span>
                                                  {item.seller_order
                                                    .seller_etsy_orders.isGift
                                                    ? 'Evet'
                                                    : 'Hayır'}
                                                </span>
                                                <Tooltip
                                                  anchorSelect={`#isGift${idx}`}
                                                  content={
                                                    `Mesaj:${
                                                      item?.seller_order
                                                        ?.seller_etsy_orders
                                                        .giftMessage
                                                        ? item?.seller_order
                                                            ?.seller_etsy_orders
                                                            .giftMessage
                                                        : '-'
                                                    }` +
                                                    '/ ' +
                                                    `Alıcı Mesajı:${
                                                      item?.seller_order
                                                        ?.seller_etsy_orders
                                                        .buyerMessage
                                                        ? item?.seller_order
                                                            ?.seller_etsy_orders
                                                            .buyerMessage
                                                        : '-'
                                                    }`
                                                  }
                                                />
                                                <span
                                                  className="ml-1"
                                                  id={`isGift${idx}`}
                                                >
                                                  <InfoCircle
                                                    size={16}
                                                    color="#111827"
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </td>

                                <td className="py-4 text-left text-xs md:text-xs text-gray-500">
                                  <div className="flex flex-col items-start space-y-1">
                                    <div className="text-md font-bold whitespace-nowrap">
                                      <span className="text-[#1F2937] text-lg">
                                        ${' '}
                                        {Number(
                                          order.seller_order_items.reduce(
                                            (acc: any, item: any) =>
                                              acc +
                                              Number(
                                                item.seller_product
                                                  .supplierProductVariantPrice
                                              ) *
                                                item.quantity,
                                            0
                                          )
                                        ).toFixed(2)}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td className="py-4 px-2 text-xs text-left  text-gray-500">
                                  {order?.seller_order_items[0].seller_order
                                    .labelPdf ? (
                                    <div className="flex justify-center w-40">
                                      <button
                                        className="flex justify-center items-center space-x-1 rounded-full py-1 px-2 w-28 bg-[#E3211E] text-white"
                                        onClick={() =>
                                          downloadLabel(
                                            order?.seller_order_items[0]
                                              .seller_order.labelPdf
                                          )
                                        }
                                      >
                                        <Ticket size="24" color="white" />
                                        <p>Etiket İndir</p>
                                      </button>
                                    </div>
                                  ) : (
                                    <p className="w-full text-center">
                                      Kargo etiketi henüz oluşturulmamış
                                    </p>
                                  )}
                                </td>
                                <td>
                                  {order?.seller_order_items[0]?.designFiles
                                    ?.length < 1 ? (
                                    <div className="w-72 flex flex-col items-center">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return item?.seller_product?.mockupImages?.map(
                                            (mockup: any, index: number) => {
                                              return (
                                                <div className="flex flex-col items-center justify-center w-full h-[200px]">
                                                  <img
                                                    onLoad={() => {}}
                                                    className="w-24 aspect-square object-contain object-center rounded-lg"
                                                    src={`${API_URL}${mockup}`}
                                                    alt="Mockup"
                                                  />
                                                  <a
                                                    download
                                                    target="_blank"
                                                    className="bg-[#FB923C] rounded-full w-1/2 py-1 px-2 block text-center text-xs text-white mt-1"
                                                    href={`${API_URL}${item?.seller_product?.designImages[index]}`}
                                                    rel="noreferrer"
                                                  >
                                                    İndir
                                                  </a>
                                                </div>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div className="flex flex-col items-center justify-center">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return item?.designFiles?.map(
                                            (design: any, index: number) => {
                                              return (
                                                <div className="flex flex-col items-center justify-center w-full h-[200px]">
                                                  <img
                                                    onLoad={() => {}}
                                                    className="w-24 aspect-square object-contain object-center rounded-lg"
                                                    src={`${API_URL}${design}`}
                                                    alt="Design"
                                                  />
                                                  <a
                                                    download
                                                    target="_blank"
                                                    className="bg-[#FB923C] rounded-full w-1/2 py-1 px-2 block text-center text-xs text-white mt-1"
                                                    href={`${API_URL}${design}`}
                                                    rel="noreferrer"
                                                  >
                                                    İndir
                                                  </a>
                                                </div>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td className="py-4 px-4 h-full">
                                  {
                                    <div className="flex flex-col justify-start space-y-2 h-full">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return (
                                            <p
                                              className={classNames(
                                                item.note
                                                  ? 'bg-white'
                                                  : 'bg-transparent',
                                                'border-none rounded-lg focus:outline-none h-[200px] text-xs placeholder:text-xs min-w-[150px] p-2'
                                              )}
                                            >
                                              {item.note}
                                            </p>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>Sipariş bulunamadı</p>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SupplierOrdersTable;
