import { InfoCircle } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';

const FreeBanner = () => {
  const { language } = useLanguage();
  const { session } = useAuth();
  const location = useLocation();
  const [content, setContent] = useState('');

  useEffect(() => {
    if (
      !session?.isTrialEnd &&
      (location.pathname === '/trendyol-orders' ||
        location.pathname === '/trendyol-seller-dashboard')
    ) {
      setContent(language === 'tr' ? ' Deneme ' : ' Trial ');
    } else {
      setContent(language === 'tr' ? 'Rexven ücretsiz' : 'Rexven Free');
    }
  }, [language]);

  //NOTE:related with discount banner
  const [countdown, setCountdown] = useState({ days: '0', hours: '0', minutes: '0', });
  const initialTargetDate = new Date('2024-10-05T10:00:00').getTime();
  const nextTargetDate = new Date('2024-11-16T10:00:00').getTime();

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date().getTime();
      let timeRemaining = initialTargetDate - now;

      if (timeRemaining <= 0) {
        // Eğer belirlenen ilk tarih geçtiyse, yeni tarih için geri sayımı başlat
        timeRemaining = nextTargetDate - now;
      }

      if (timeRemaining > 0) {
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

        const formattedDays = days.toString().padStart(2, '0');
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        setCountdown({ days: formattedDays, hours: formattedHours, minutes: formattedMinutes });
      } else {
        setCountdown({ days: '00', hours: '00', minutes: '00' });
      }
    };

    const countdownInterval = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [initialTargetDate, nextTargetDate]);
  // useEffect(() => {
  //   const targetDate = new Date('2024-09-05T10:00:00').getTime();

  //   const calculateTimeRemaining = () => {
  //     const now = new Date().getTime();
  //     const timeRemaining = targetDate - now;

  //     if (timeRemaining > 0) {
  //       const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  //       const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //       const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

  //       // Gün, saat ve dakika değerlerini iki haneli sayılara dönüştürme
  //       const formattedDays = days.toString().padStart(2, '0');
  //       const formattedHours = hours.toString().padStart(2, '0');
  //       const formattedMinutes = minutes.toString().padStart(2, '0');

  //       setCountdown({ days: formattedDays, hours: formattedHours, minutes: formattedMinutes });
  //     } else {
  //       setCountdown({ days: '00', hours: '00', minutes: '00' });
  //     }
  //   };
  //   const countdownInterval = setInterval(calculateTimeRemaining, 1000);

  //   return () => {
  //     clearInterval(countdownInterval);
  //   };
  // }, []);
  return (
    //NOTE:free banner
    // <div className="w-full px-5 py-3 flex justify-center items-center bg-purpleLight">
    //   <div>
    //     <InfoCircle color="#5502D7" size={32} />
    //   </div>
    //   {language === 'tr' ? (
    //     <h2 className="text-sm lg:text-base font-medium text-center ml-2">
    //       Şu anda {content} versiyonu kullanıyorsunuz, premium avantajlardan
    //       yararlanmak için{' '}
    //       <a
    //         className="font-medium text-purpleDark"
    //         href="https://www.rexven.com/topluluk-paketleri"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         {' '}
    //         bilgi alın
    //       </a>
    //     </h2>
    //   ) : (
    //     <h2 className="text-sm lg:text-base font-medium text-center ml-2">
    //       You are currently using {content} version,
    //       <a
    //         className="font-medium text-purpleDark"
    //         href="https://www.rexven.com/pricing"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         {' '}
    //         get information{' '}
    //       </a>
    //       to benefit from premium benefits
    //     </h2>
    //   )}
    // </div>
    //NOTE: new year banner
    // <div className="grid grid-col grid-cols-2  items-center justify-start relative w-full py-3 flex justify-center items-center" style={{ backgroundImage: `url('/images/timer.webp')`, backgroundSize: 'cover', backgroundPosition: 'right', width: '100%', height: '290px' }}>
    //   <div className='col-span-2 sm:col-span-1 px-6 md:px-[60px]'>
    //     <p className='text-xs sm:text-sm'>Yıllık üyeliklerde</p>
    //     <p className='text-[#3B82F6] font-bold text-3xl sm:text-5xl xl:text-6xl 2xl:text-7xl'>%46 <span className='font-normal text-md'>Kâr Et!</span> </p>
    //     <Link
    //       type="button"
    //       className="rounded-md bg-[#2563EB] text-white py-2 px-4 text-sm"
    //       to={'/payment/57?coupon=WLJWVOSHB'}
    //       target='_blank'
    //     >
    //       Premium Ol
    //     </Link>
    //     <p className='text-xs sm:text-xs mt-2'>
    //       Rexven Yıllık Üyelikleri 17 Ocak'ta
    //       15 bin TL olacak. Fiyat artışı olmadan
    //       önce son <br className='hidden 2xl:block' /> yaptığımız  indirimi uzattık.
    //       8 bin TL’ye yıllık üyelikten faydalanabilirsin.
    //     </p>
    //   </div>
    //   <div className='grid grid-col grid-cols-3 gap-6 sm:gap-0 justify-center items-center col-span-2 sm:col-span-1 text-xl md:text-3xl xl:text-4xl font-extrabold mt-4 sm:mt-0 2xl:-ml-40' >
    //     <div className='col-span-1 text-center'>
    //       <p className="rounded border-2 border-[#2563EB]   inline-block min-w-[40px] sm:min-w-[50px] mr-1">
    //         {`${countdown.days.toString()[0] === undefined ? '0' : countdown.days.toString()[0]}`}
    //       </p>
    //       <p className="rounded border-2 border-[#2563EB]   inline-block min-w-[40px] sm:min-w-[50px]">
    //         {`${countdown.days.toString()[1] === undefined ? '0' : countdown.days.toString()[1]}`}
    //       </p>
    //       <p className='text-[#1F2937] font-semibold mt-3'>GÜN</p>
    //     </div>
    //     <div className='col-span-1 text-center 2xl:-ml-52'>
    //       <p className="rounded border-2 border-[#2563EB]    inline-block min-w-[40px] sm:min-w-[50px] mr-1">
    //         {`${countdown.hours.toString()[0] === undefined ? '0' : countdown.hours.toString()[0]}`}
    //       </p>
    //       <p className="rounded border-2 border-[#2563EB]   inline-block min-w-[40px] sm:min-w-[50px]">
    //         {`${countdown.hours.toString()[1] === undefined ? '0' : countdown.hours.toString()[1]}`}
    //       </p>
    //       <p className='text-[#1F2937] font-semibold mt-3'>SAAT</p>
    //     </div>
    //     <div className='col-span-1 text-center 2xl:-ml-96'>
    //       <p className="rounded border-2 border-[#2563EB]   inline-block min-w-[40px] sm:min-w-[50px] mr-1">
    //         {`${countdown.minutes.toString()[0] === undefined ? '0' : countdown.minutes.toString()[0]}`}
    //       </p>
    //       <p className="rounded border-2 border-[#2563EB]   inline-block min-w-[40px] sm:min-w-[50px]">
    //         {`${countdown.minutes.toString()[1] === undefined ? '0' : countdown.minutes.toString()[1]}`}
    //       </p>
    //       <p className='text-[#1F2937] font-semibold mt-3'>DAKİKA</p>
    //     </div>
    //   </div>
    // </div>
    //NOTE: camp banner
    <div className="grid grid-col grid-cols-12 items-center justify-start relative w-full py-3 bg-gradient-to-r from-[#1E3A8A] to-[#111827]">
      <div className='col-span-12 lg:col-span-5 px-4 lg:px-0 lg:pl-4'>
        <p className='text-[#F9FAFB] font-bold xl:text-lg'>Yeni Kurduğumuz Tedarik Sistemini Anlatacağız</p>
        <span className='text-[#F9FAFB] text-sm xl:text-lg font-light'> İstanbul</span>
      </div>
      <div className='col-span-12 lg:col-span-5 mt-4 sm:mt-0 lg:text-center pl-4 lg:px-0'>
        <div className='text-center inline-block mr-1 lg:mr-2'>
          <div className="flex justify-center">
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] 2xl:w-[70px] 2xl:h-[70px] text-[32px] flex items-center justify-center mr-1">
              {`${countdown.days.toString()[0] === undefined ? '0' : countdown.days.toString()[0]}`}
            </p>
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] 2xl:w-[70px] 2xl:h-[70px] text-[32px] flex items-center justify-center">
              {`${countdown.days.toString()[1] === undefined ? '0' : countdown.days.toString()[1]}`}
            </p>
            <div className='inline-block my-auto ml-1 text-white text-xl font-extrabold'>
              :
            </div>
          </div>
          <p className='text-[#FFFFFF] mt-3 text-[16px]'>GÜN</p>
        </div>
        <div className='text-center inline-block mr-1 lg:mr-2'>
          <div className="flex justify-center">
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] 2xl:w-[70px] 2xl:h-[70px] text-[32px] flex items-center justify-center mr-1">
              {`${countdown.hours.toString()[0] === undefined ? '0' : countdown.hours.toString()[0]}`}
            </p>
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] 2xl:w-[70px] 2xl:h-[70px] text-[32px] flex items-center justify-center">
              {`${countdown.hours.toString()[1] === undefined ? '0' : countdown.hours.toString()[1]}`}
            </p>
            <div className='inline-block my-auto ml-1 text-white text-xl font-extrabold'>
              :
            </div>
          </div>
          <p className='text-[#FFFFFF] mt-3 text-[16px]'>SAAT</p>
        </div>
        <div className='text-center inline-block'>
          <div className="flex justify-center">
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] 2xl:w-[70px] 2xl:h-[70px] text-[32px] flex items-center justify-center mr-1">
              {`${countdown.minutes.toString()[0] === undefined ? '0' : countdown.minutes.toString()[0]}`}
            </p>
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] 2xl:w-[70px] 2xl:h-[70px] text-[32px] flex items-center justify-center">
              {`${countdown.minutes.toString()[1] === undefined ? '0' : countdown.minutes.toString()[1]}`}
            </p>
          </div>
          <p className='text-[#FFFFFF] mt-3 text-[16px]'>DAKİKA</p>
        </div>

      </div>

      <div className='col-span-12 lg:col-span-2 pl-4 lg:px-0 mt-4 sm:mt-0'>
        <Link
          type="button"
          className="rounded-[12px]  bg-gradient-to-r from-[#4F46E5] to-[#2563EB] text-white py-2 px-12 lg:px-8 2xl:px-14 font-bold text-sm 2xl:text-lg"
          to={'https://api.whatsapp.com/send?phone=905352051963&text=Merhaba%20Yüz%20yüze%20Amazon%20ve%20Etsy%20kampı%20hakkında%20bilgi%20almak%20istiyorum'}
          target='_blank'
        >
          Bilgi Al
        </Link>
      </div>
    </div>
  );
};

export default FreeBanner;

{/* <div className="flex justify-center">
<p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] flex items-center justify-center mr-1">
  {`${countdown.days.toString()[0] === undefined ? '0' : countdown.days.toString()[0]}`}
</p>
<p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] flex items-center justify-center">
  {`${countdown.days.toString()[1] === undefined ? '0' : countdown.days.toString()[1]}`}
</p>
</div>
<p className='text-[#FFFFFF] mt-3'>GÜN</p> */}

{/* <div className="flex justify-center">
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] flex items-center justify-center mr-1">
              {`${countdown.hours.toString()[0] === undefined ? '0' : countdown.hours.toString()[0]}`}
            </p>
            <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] flex items-center justify-center">
              {`${countdown.hours.toString()[1] === undefined ? '0' : countdown.hours.toString()[1]}`}
            </p>
          </div>
          <p className='text-[#FFFFFF] mt-3'>SAAT</p> */}

        //   <div className="flex justify-center">
        //   <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] flex items-center justify-center mr-1">
        //     {`${countdown.minutes.toString()[0] === undefined ? '0' : countdown.minutes.toString()[0]}`}
        //   </p>
        //   <p className="rounded-md bg-white text-[#1E3A8A] w-[50px] h-[50px] flex items-center justify-center">
        //     {`${countdown.minutes.toString()[1] === undefined ? '0' : countdown.minutes.toString()[1]}`}
        //   </p>
        // </div>
        // <p className='text-[#FFFFFF] mt-3'>DAKİKA</p>