import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import httpClient from '../../utils/httpClient';
import alertNotification from '../../utils/alertNotification';
import API_URL from '../../config';
import { useDebounce } from 'use-debounce';
import { ToastContainer } from 'react-toastify';
import marketplaceService from '../../services/marketplaceService';
import { EtsyOrder } from '../../pages/MarketplaceOrders';
import { useSearchParams } from 'react-router-dom';
import { SearchNormal } from 'iconsax-react';

interface OrderMatchingModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrder: any;
  page?: any;
  getOrders: () => void;
  setCargoPrices?: React.Dispatch<React.SetStateAction<any[]>>;
  cargoPrices?: any[];
  queryParams?: any;
  withoutCalculate?: boolean;
  // refetchData: () => void;
}
interface Product {
  id: number;
  title: string;
  images: string[];
}
const supplierGifts = [
  { supplierId: 3, productId: 2756 },
  { supplierId: 6, productId: 2757 },
  { supplierId: 5, productId: 2758 },
];

const OrderMatchingModal: React.FC<OrderMatchingModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrder,
  getOrders,
  queryParams,
  page,
  cargoPrices,
  setCargoPrices,
  withoutCalculate,

  // refetchData,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchParams] = useSearchParams();
  const [value] = useDebounce(searchTerm, 600);
  const [selectedProduct, setSelectedProduct] = useState<{
    [key: number]: any | null;
  } | null>(null);

  const handleProductSelect = (index: number, product: Product) => {
    setSelectedProduct((prevSelectedProducts) => ({
      ...prevSelectedProducts,
      [index]: product,
    }));
  };

  const handleMatch = async () => {
    if (!selectedProduct) {
      alertNotification('error', 'Lütfen tüm ürünleri eşleştirin');
      return;
    }
    if (
      selectedOrder.seller_order_items.filter(
        (item: any) => item.seller_product.title !== 'Gift Wrap'
      ).length !== Object.keys(selectedProduct).length
    ) {
      alertNotification('error', 'Lütfen tüm ürünleri eşleştirin');
      return;
    }
    setLoading(true);
    const temp = selectedOrder.seller_order_items.map(
      (item: any, index: number) => {
        return {
          sellerProductId: item.seller_product.id,
          supplierProductVariantId:
            item.seller_product.title === 'Gift Wrap'
              ? supplierGifts.find((prod: any) => {
                  return (
                    Number(prod.supplierId) ===
                    Number(Object.values(selectedProduct)[index - 1].supplierId)
                  );
                })?.productId
              : selectedProduct[index]?.id,
          sellerOrderItemId: item.id,
        };
      }
    );

    try {
      await httpClient.post(
        !withoutCalculate
          ? `/api/v1/marketplace/seller/product-match`
          : `/api/v1/marketplace/seller/product-detail-match`,
        !withoutCalculate
          ? { matchingData: temp } // İlk koşulda matchingData ile birlikte gönder
          : {
              // sellerOrderItemId: Number(temp[0].sellerOrderItemId),
              sellerProductId: Number(temp[0].sellerProductId),
              supplierProductVariantId: Number(
                temp[0].supplierProductVariantId
              ), // Düz obje olarak gönder
            }
      );
      alertNotification('success', 'Sipariş eşleştirme başarılı');
      getOrders();
      setIsModalOpen(false);
      setSelectedProduct(null);

      setTimeout(async () => {
        if (searchParams.get('label') === 'waitingMatch') {
          //sipariş eşleştiği için artık waitingPayment pozisyonunda

          const res = await marketplaceService.getEtsyOrders(page, {
            ...queryParams,
            label: 'waitingPayment',
          });
          const temp = res?.data?.data;
          //sadece eşleştirme yaptığımız order'ı bulup onun cargoPrice'ını güncelleyeceğiz
          const matchedOrder = temp.find(
            (item: any) => item.id === selectedOrder.id
          );
          if (matchedOrder) {
            const updatedCargoPrices = cargoPrices?.map((item) => {
              if (item.orderId === matchedOrder.id) {
                return {
                  orderId: matchedOrder.id,
                  shipmentCost: matchedOrder.shipmentCost,
                  allShipmentCost: matchedOrder.allShipmentCost,
                  orderItems: matchedOrder.seller_order_items,
                  serviceCode: matchedOrder.serviceCode,
                };
              }
              return item; // eşleşmeyen itemları olduğu gibi bırak
            });

            if (updatedCargoPrices) {
              setCargoPrices && setCargoPrices(updatedCargoPrices)!;
            }
          }
        } else {
          const res = await marketplaceService.getEtsyOrders(page, queryParams);
          const groupedById = res.data.data.reduce((acc: any, current: any) => {
            const { id, seller_order_items } = current;

            if (!acc[id]) {
              acc[id] = {
                ...current,
                seller_order_items: [...seller_order_items],
              };
            } else {
              acc[id].seller_order_items.push(...seller_order_items);
            }

            return acc;
          }, {});
          const temp = Object.values(groupedById).sort(
            (a: any, b: any) =>
              (new Date(b.orderDate) as any) - (new Date(a.orderDate) as any)
          ) as EtsyOrder[];
          const cargos = temp.map((order) => {
            return {
              orderId: order.id,
              shipmentCost: order.shipmentCost,
              allShipmentCost: order.allShipmentCost,
              orderItems: order.seller_order_items,
              serviceCode: order.serviceCode,
            };
          });
          setCargoPrices && setCargoPrices(cargos);
        }
      }, 8000);
    } catch (error) {
      console.error('Failed to match order with product:', error);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await httpClient.get(
        `/api/v1/marketplace/all-products?keyword=${value}`
      );
      const arr: any = [];
      response.data.data.data.map((item: any) => {
        return item.supplier_product_variants.map((variant: any) => {
          arr.push({ ...variant, images: item.images });
        });
      });

      setProducts(arr);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoadingProducts(false);
    }
  };

  useEffect(() => {
    if (!isModalOpen) return;
    setLoadingProducts(true);

    fetchProducts();
  }, [cargoPrices, isModalOpen, value]);

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => {
          setSelectedProduct(null);
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <ToastContainer />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl h-[60vh] 3xl:max-w-5xl transform overflow-auto  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Sipariş Eşleştirme
                </Dialog.Title>
                <div className="mt-2 flex flex-col overflow-y-auto">
                  {selectedOrder?.seller_order_items.map(
                    (order: any, index: number) => {
                      if (order.seller_product.title === 'Gift Wrap') {
                        return null;
                      }
                      return (
                        <div className="flex space-x-2">
                          <div className="flex flex-col flex-1">
                            <div className="flex flex-row items-center border border-[#6B7280] rounded-xl p-2">
                              <img
                                src={
                                  order.seller_product.images[0]
                                    ? order.seller_product.images[0]
                                    : '/images/image-not-found.png'
                                }
                                alt="Order item"
                                className="w-20 h-20 3xl:w-20 3xl:h-20 rounded-lg"
                              />
                              <div className="flex flex-col pl-2">
                                <p className="text-sm">
                                  {order?.seller_product.title.substring(0, 70)}
                                  ...
                                </p>
                                <div>
                                  {order?.seller_product.attributes.length >
                                    0 &&
                                    order?.seller_product.attributes.map(
                                      (item: any) => {
                                        return Object.entries(item).map(
                                          ([key, value], index) => (
                                            <div
                                              key={index}
                                              className="flex text-sm"
                                            >
                                              <p className="font-bold  mr-2">
                                                {key}:
                                              </p>
                                              <p>{value as string}</p>
                                            </div>
                                          )
                                        );
                                      }
                                    )}
                                  {
                                    <span className="font-normal">
                                      {Object.keys(order.customizationData)
                                        .length > 0
                                        ? order.customizationData
                                            .splice(9)
                                            .join(' ')
                                            .replaceAll('- Ek Maliyet: 0\n', '')
                                        : '-'}
                                    </span>
                                  }
                                </div>
                              </div>
                            </div>

                            {selectedProduct ? (
                              <div className="mt-8 mr-4 flex items-center space-x-2 mb-4">
                                {selectedProduct[index]?.images[0] && (
                                  <img
                                    src={
                                      API_URL +
                                      'images/marketplace/supplier-images/' +
                                      selectedProduct[index]?.images[0]
                                    }
                                    alt="Product"
                                    className="w-16 h-16 3xl:w-20 3xl:h-20 object-cover mr-2 rounded-lg"
                                  />
                                )}
                                <p className="text-sm">
                                  {selectedProduct[index] &&
                                    selectedProduct[index]?.name}
                                </p>
                              </div>
                  ):selectedOrder?.seller_order_items[0]?.suppplierProductVariantImages&& selectedOrder?.seller_order_items[0]?.suppplierProductVariantImages.length > 0 ?(
                    <div className="mt-8 mr-4 flex items-center space-x-2 mb-4">
                                {selectedOrder?.seller_order_items[0]?.suppplierProductVariantImages&& selectedOrder?.seller_order_items[0]?.suppplierProductVariantImages.length > 0 && (
                                  <img
                                    src={
                                      API_URL +
                                      'images/marketplace/supplier-images/' +
                                      selectedOrder?.seller_order_items[0]?.suppplierProductVariantImages[0]
                                    }
                                    alt="Product"
                                    className="w-16 h-16 3xl:w-20 3xl:h-20 object-cover mr-2 rounded-lg"
                                  />
                                )}
                                <p className="text-sm">
                                  {selectedOrder?.seller_order_items[0]?.supplierProductName}
                                </p>
                              </div>
                  ) : null}
                          </div>

                          <div className="flex-1">
                            <div className="mb-4 flex items-center">
                              <div className="flex justify-between items-center w-full border border-[#6B7280] rounded-full px-2">
                                <input
                                  type="text"
                                  placeholder="Ara..."
                                  className="w-full  px-2 ml-2 border-none focus:ring-0 focus:outline-none focus:border-none"
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                />
                                <SearchNormal size={24} color="#6B7280" />
                              </div>
                            </div>
                            {loadingProducts ? (
                              <div className="flex justify-center items-center h-60">
                                Ürünler yükleniyor...
                              </div>
                            ) : products.length > 0 ? (
                              <ul className="max-h-60 overflow-y-auto">
                                {products.map((product: any) => (
                                  <li
                                    key={product.id}
                                    className="flex text-sm items-center cursor-pointer p-2 hover:bg-gray-100 border border-[#6B7280] rounded-xl mb-2 shadow-md"
                                    onClick={() =>
                                      handleProductSelect(index, product)
                                    }
                                  >
                                    {product?.images[0] && (
                                      <img
                                        src={
                                          API_URL +
                                          'images/marketplace/supplier-images/' +
                                          product?.images[0]
                                        }
                                        alt="Product"
                                        className="w-16 h-16 object-cover mr-2"
                                      />
                                    )}
                                    {product?.name}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <div className="bg-[#DBEAFE] rounded-xl p-3 text-[#2563EB]">
                                <p className="font-medium text-sm">
                                  Ürün Eşleştirme
                                </p>
                                <p className="text-xs">
                                  Ürünler SKU veya ismi ile aranabilir. Lütfen
                                  ürün araması için en az 3 karakter giriniz
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    disabled={loading}
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-[#2563EB] px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleMatch}
                  >
                    {loading ? 'Ürünler Eşleştiriliyor...' : 'Eşleştir'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OrderMatchingModal;
