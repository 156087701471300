import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import marketplaceService from '../../services/marketplaceService';
import { useAuth } from '../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { handleRequest } from 'msw';
import { useSearchParams } from 'react-router-dom';
interface Props {
  isModalOpen: boolean;
  setPlanId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  balance: number;
  planId: number;
  setEftModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStripeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDepositAmount: React.Dispatch<React.SetStateAction<number>>;
}
const WalletModal: React.FC<Props> = ({
  isModalOpen,
  setIsStripeModalOpen,
  setIsModalOpen,
  balance,
  setPlanId,
  planId,
  setEftModalOpen,
  setDepositAmount,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const packages = [
    { id: 600, amount: 250 },
    { id: 601, amount: 500 },
    { id: 602, amount: 750 },
    { id: 603, amount: 1000 },
    { id: 604, amount: 1250 },
    { id: 605, amount: 1500 },
    { id: 606, amount: 1750 },
    { id: 607, amount: 2000 },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = packages.find(
      (item: any) => item.id === Number(event.target.value)
    );
    if (temp) {
      setDepositAmount(temp.amount);
      setPlanId(Number(event.target.value));
    }
    setSearchParams('plan=' + event.target.value);
  };

  // useEffect(() => {
  //   // if (isModalOpen && inputRef.current) {
  //   //   inputRef?.current.focus();
  //   // }
  //   if (!isModalOpen) {
  //     return;
  //   }
  // }, [isModalOpen]);

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white py-8 px-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-start leading-6"
                >
                  <div className="border-b-2 border-lightGray">
                    <p className="text-[24px] font-bold text-headerPrimary mb-2">
                      Cüzdan:{' '}
                    </p>
                    <p className="text-4xl font-bold text-[#059669]">
                      ${Number(balance).toFixed(2)}
                    </p>
                  </div>
                </Dialog.Title>
                <p className="my-4 ml-1">
                  Hesabınıza aktaracağınız miktarı seçiniz
                </p>
                <div className="w-full grid grid-cols-12 rounded-xl p-4 bg-[#F9FAFB] mx-auto">
                  {packages.map((item: any, index) => {
                    return (
                      <div
                        key={item.id}
                        className="relative col-span-6 flex mb-4"
                      >
                        <div className="flex h-6 items-center">
                          <input
                            value={item.id}
                            checked={item.id === planId}
                            onChange={handleChange}
                            type="radio"
                            id={`plan-${index}`}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-4 text-[24px] leading-6 font-medium">
                          <label
                            htmlFor={`plan-${index}`}
                            className=" text-[#374151]"
                          >
                            ${item.amount}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="text-[#1D4ED8] text-sm mt-4">
                  {' '}
                  Kredi kartı ile ödeme işleminde %5 Stripe kesintisi
                  yansıtılmaktadır.
                </p>
                <div className="flex justify-between items-center space-x-2 mt-4">
                  <button
                    className="border-[#1D4ED8] border rounded-lg text-[#1D4ED8] font-medium px-4 py-1 w-full"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsStripeModalOpen(true);
                    }}
                  >
                    Kredi Kartı
                  </button>
                  <button
                    className="bg-[#1D4ED8] text-white rounded-lg font-medium px-4 py-1 w-full"
                    onClick={() => {
                      setIsModalOpen(false);
                      setEftModalOpen(true);
                    }}
                  >
                    Havale/EFT
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WalletModal;
