import React from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { format } from 'date-fns';

import tr from 'date-fns/locale/tr';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

export const COUNTRIES = [
  { code: 'AU', name: 'Australia - AU' },
  { code: 'AT', name: 'Austria - AT' },
  { code: 'BE', name: 'Belgium - BE' },
  { code: 'BG', name: 'Bulgaria - BG' },
  { code: 'CA', name: 'Canada - CA' },
  { code: 'HR', name: 'Croatia - HR' },
  { code: 'CY', name: 'Cyprus - CY' },
  { code: 'CZ', name: 'Czech Republic - CZ' },
  { code: 'DK', name: 'Denmark - DK' },
  { code: 'EE', name: 'Estonia - EE' },
  { code: 'FI', name: 'Finland - FI' },
  { code: 'FR', name: 'France - FR' },
  { code: 'DE', name: 'Germany - DE' },
  { code: 'GR', name: 'Greece - GR' },
  { code: 'HK', name: 'Hong Kong - HK' },
  { code: 'HU', name: 'Hungary - HU' },
  { code: 'IN', name: 'India - IN' },
  { code: 'ID', name: 'Indonesia - ID' },
  { code: 'IE', name: 'Ireland - IE' },
  { code: 'IL', name: 'Israel - IL' },
  { code: 'IT', name: 'Italy - IT' },
  { code: 'LV', name: 'Latvia - LV' },
  { code: 'LT', name: 'Lithuania - LT' },
  { code: 'LU', name: 'Luxembourg - LU' },
  { code: 'MY', name: 'Malaysia - MY' },
  { code: 'MT', name: 'Malta - MT' },
  { code: 'MX', name: 'Mexico - MX' },
  { code: 'MA', name: 'Morocco - MA' },
  { code: 'NL', name: 'Netherlands - NL' },
  { code: 'NZ', name: 'New Zealand - NZ' },
  { code: 'NO', name: 'Norway - NO' },
  { code: 'PH', name: 'Philippines - PH' },
  { code: 'PL', name: 'Poland - PL' },
  { code: 'PT', name: 'Portugal - PT' },
  { code: 'RO', name: 'Romania - RO' },
  { code: 'SG', name: 'Singapore - SG' },
  { code: 'SK', name: 'Slovakia - SK' },
  { code: 'SI', name: 'Slovenia - SI' },
  { code: 'ZA', name: 'South Africa - ZA' },
  { code: 'ES', name: 'Spain - ES' },
  { code: 'SE', name: 'Sweden - SE' },
  { code: 'CH', name: 'Switzerland - CH' },
  { code: 'TR', name: 'Türkiye - TR' },
  { code: 'GB', name: 'United Kingdom - GB' },
  { code: 'US', name: 'United States - US' },
  { code: 'VN', name: 'Vietnam - VN' },
];

interface Props {
  setQueryParams: React.Dispatch<
    React.SetStateAction<{
      shop: string;
      country: string;
      startDate: any;
      endDate: any;
      isGift: boolean | string;
      customer: string;
      label?: string;
      orderId?: string;
    }>
  >;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  page: number | string;
  queryParams: {
    shop: string;
    country: string;
    startDate: any;
    endDate: any;
    isGift: boolean | string;
    customer: string;
    label?: string;
    orderId?: string;
  };
  shops?: any[];
}
const OrderFilterForm: React.FC<Props> = ({
  queryParams,
  setQueryParams,
  page,
  setInputPage,
  setPage,
  setQueryString,
  shops,
}) => {
  registerLocale('tr', tr);
  setDefaultLocale('tr');
  const navigate = useNavigate();
  const handleSubmit = (values: any) => {
    setPage(1);
    setInputPage(1);
    setQueryParams({
      shop: String(values.shop) || '',
      country: values.country || '',
      startDate:
        values.startDate !== null
          ? format(new Date(values.startDate), 'yyyy-MM-dd')
          : '',
      endDate:
        values.endDate !== null
          ? format(new Date(values.endDate), 'yyyy-MM-dd')
          : '',
      isGift: values.isGift === 'yes' ? true : false || '',
      customer: values.customer || '',
      label: values.label || '',
      orderId: values.orderId || ''
    });
    setQueryString(
      `shop=${values.shop}&country=${values.country}&startDate=${
        values.startDate === null ? '' : values.startDate
      }&endDate=${values.endDate === null ? '' : values.endDate}&isGift=${
        values.isGift === 'yes' ? true : false
      }&customer=${values.customer}&label=${values.label}&orderId=${values.orderId}`
    );
    navigate(
      `?page=${1}&shop=${values.shop}&country=${values.country}&startDate=${
        values.startDate === null ? '' : values.startDate
      }&endDate=${values.endDate === null ? '' : values.endDate}&isGift=${
        values.isGift === 'yes' ? true : false
      }&customer=${values.customer}&label=${values.label}&orderId=${values.orderId}`
    );
  };

  const formik = useFormik({
    initialValues: {
      shop: queryParams.shop || '',
      country: queryParams.country || '',
      startDate: queryParams.startDate ? new Date(queryParams.startDate) : null,
      endDate: queryParams.endDate ? new Date(queryParams.endDate) : null,
      isGift: queryParams.isGift || '',
      customer: queryParams.customer || '',
      label: queryParams.label || '',
      orderId: queryParams.orderId || '',
    },
    onSubmit: handleSubmit,
  });
  return (
    <div className="bg-white rounded-xl p-4">
      <form className="grid grid-cols-8 gap-2" onSubmit={formik.handleSubmit}>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
            Mağazalar
          </label> */}
          <select
            name="shop"
            id="shop"
            value={formik.values.shop}
            onChange={formik.handleChange}
            className="border-[#6B7280] rounded-md text-sm w-full"
          >
            <option value="">Tüm Mağazalar</option>
            {shops?.map((shop) => (
              <option key={shop.id} value={shop.id}>
                {shop.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
            Ülke
          </label> */}
          <select
            name="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            id="country"
            className="border-[#6B7280] rounded-md text-sm w-full"
          >
            <option value="">Tüm Ülkeler</option>
            {COUNTRIES.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
            Başlangıç
          </label> */}
          <DatePicker
            className="border-[#6B7280] rounded-md text-sm pr-0 w-full"
            selected={formik.values.startDate}
            onChange={(date) => {
              formik.setFieldValue('startDate', date);
            }}
            maxDate={new Date()}
            placeholderText="Başlangıç tarihi"
            monthsShown={1}
            locale={tr}
          />
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
            Bitiş
          </label> */}
          <DatePicker
            className="border-[#6B7280] rounded-md text-sm pr-0 w-full"
            selected={formik.values.endDate}
            placeholderText="Bitiş tarihi"
            onChange={(date) => {
              formik.setFieldValue('endDate', date);
            }}
            // startDate={new Date()}
            maxDate={new Date()}
            monthsShown={1}
            locale={tr}
          />
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
            Hediye?
          </label> */}
          <div className="flex items-center space-x-2">
            <select
              name="isGift"
              id="isGift"
              value={formik.values.isGift.toString()}
              onChange={formik.handleChange}
              className="border-[#6B7280] rounded-md text-sm w-full"
            >
              <option value="">Hediye mi?</option>
              <option value={'yes'}>Evet</option>
              <option value={'no'}>Hayır</option>
            </select>
          </div>
        </div>
        <div className="col-span-4 xl:col-span-1 flex items-end">
        
          <div className="flex items-center space-x-2">
          <input
            type="text"
            name="orderId"
            value={formik.values.orderId}
            onChange={formik.handleChange}
            placeholder="Sipariş No"
            id="orderId"
            className="rounded-lg border-[#6B7280] text-sm w-full"
          />
          </div>
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1 flex items-end">
          <input
            type="text"
            name="customer"
            value={formik.values.customer}
            onChange={formik.handleChange}
            placeholder="Müşteri ismi"
            id="customer"
            className="rounded-lg border-[#6B7280] text-sm w-full"
          />
        </div>
        <div className="col-span-8 md:col-span-2 xl:col-span-1 flex items-end">
          <button
            type="submit"
            className="bg-[#1D4ED8] text-white px-4 py-2 rounded-lg w-full"
          >
            Filtrele
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderFilterForm;
